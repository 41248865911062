
import * as React from 'react'
import { useState, useRef } from 'react'
import { Button, Card, Form, Row, Col, Modal } from 'react-bootstrap'
import { BsSearch, BsFileEarmarkExcel, BsPencilSquare, BsTrash } from 'react-icons/bs' // Usamos íconos
import { CgAdd } from 'react-icons/cg'
import { useSelector } from 'react-redux'
import { RootState } from '../../../Store/store'
import * as XLSX from 'xlsx'
import DataTable from 'react-data-table-component'
import { MsgInformativo } from '../../../Utils/Toast/msgInformativo'
import DTOEmpleado from './DTO/DTOEmpleado'
import CatEmpleados from '../../../Services/Catalogos/CatEmpleados'
import { wrap } from 'node:module'
import { parse } from 'node:path'

export interface IRptRelacionBookPrectaProps {}

export default function CatRelacionBookPrecta(
  props: IRptRelacionBookPrectaProps
) {
  const mInvoices = useSelector(
    (state: RootState) => state.CatRelacionBookPrectaSlice
  )

  const [filtro, setFiltro] = useState('')
  const [Data, setData] = useState<Array<DTOEmpleado>>([])
  const [filteredData, setFilteredData] = useState<Array<DTOEmpleado>>([])
  const [show, setShowMsg] = useState(false)
  const [msg, setMsg] = useState('')
  const [header, setHeader] = useState('')
  const msgColor = 'primary'
  const gridRef = useRef<any>(null)

  const [showModal, setShowModal] = useState(false)

  const [idEmpNo, setIdEmpNo] = useState(0)
  const [ac, setAc] = useState(0)
  const [newName, setNewName] = useState('')
  const [newNumEmpleado , setNumEpleado] = useState('')
  const [newDepartamento , setNewDepartamento]  = useState('')
  const [newHoraEntrada, setNewHoraEntrada] = useState('')
  const [newHoraSalida, setNewHoraSalida] = useState('')
  const [activo, setActivo] = useState(false) // Nuevo estado para el campo Activo
  const [payroll, setPayroll] = useState('') // Estado para el campo Payroll
  const [oficina, setOficina] = useState('') // Estado para el campo Oficina
  const [horaEntradaComer, setHoraEntradaComer] = useState('') // Estado para HoraEntradaComer
  const [horaSalidaComer, setHoraSalidaComer] = useState('') // Estado para HoraSalidaComer
  const [fechaIngreso , setFechaIngreso] = useState(currentDate())
  const [editando, setEditando] = useState(false)

  const columnsRelacionBookPrecta = [
    // {
    //   name: 'ID Empleado',
    //   selector: (row: DTOEmpleado) => row.idEmpNo,
    //   sortable: true,
    //   width: '150px'
    // },
    // {
    //   name: 'AC',
    //   selector: (row: DTOEmpleado) => row.ac,
    //   sortable: true,
    //   width: '80px'
    // },
    
    {
      name: 'NumEmpleado', 
      selector: (row: DTOEmpleado)  => row.numEmpleado, 
      sortable : true, 
      width : '200px'
    },
    {
      name: 'Nombre',
      selector: (row: DTOEmpleado) => row.name,
      sortable: true,
      wrap: true,
      width: '200px'
    },
    {
      name: 'Departamento', 
      selector: (row: DTOEmpleado) => row.departamento, 
      sortable : true, 
      width : '200px'
    }, 
    {
      name: 'Hora Entrada',
      selector: (row: DTOEmpleado) => row.horaEntrada,
      sortable: true,
      width: '150px'
    },
    {
      name: 'Hora Salida',
      selector: (row: DTOEmpleado) => row.horaSalida,
      sortable: true,
      width: '150px'
    },
    // {
    //   name: 'Payroll',
    //   selector: (row: DTOEmpleado) => row.payroll,
    //   sortable: true,
    //   width: '150px'
    // },
    {
      name: 'Oficina',
      selector: (row: DTOEmpleado) => row.oficina,
      sortable: true,
      width: '220px'
    },

    // {
    //   name: 'FechaIngreso', 
    //   selector: (row: DTOEmpleado) => row.fechaIngreso, 
    //   sortable : true, 
    //   width: '220px'  
    // }, 
    {
      name: 'FechaIngreso',
      selector: (row: DTOEmpleado) => {
        if (!row.fechaIngreso) {
          return ''; // Devuelve una cadena vacía si no hay fecha
        }
        const fecha = new Date(row.fechaIngreso);
        return `${fecha.getDate().toString().padStart(2, '0')}-${(fecha.getMonth() + 1).toString().padStart(2, '0')}-${fecha.getFullYear()}`;
      },
      sortable: true,
      width: '220px'
    }, 
    
    
    // {
    //   name: 'Hora Salida Comer',
    //   selector: (row: DTOEmpleado) => row.horaSalidaComer,
    //   sortable: true,
    //   width: '200px'
    // },
    // {
    //   name: 'Hora Entrada Comer',
    //   selector: (row: DTOEmpleado) => row.horaEntradaComer,
    //   sortable: true,
    //   width: '200px'
    // },
    {
      name: 'Activo',
      width: '150px', 
      cell: (row: DTOEmpleado) => (
        <input
          type="checkbox"
          checked={row.activo}
          disabled // El checkbox es solo para mostrar, la edición se hará en el modal
        />
      ),
    },
    {
      name: 'Acciones',
      
      cell: (row: DTOEmpleado) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          <BsPencilSquare
            size={20}
            color="blue"
            onClick={() => handleOpenModal(row)}
            style={{ cursor: 'pointer' }}
          />
          <BsTrash
            size={20}
            color="red"
            onClick={() => handleDelete(row.numEmpleado)}
            style={{ cursor: 'pointer' }}
          />
        </div>
      ),
      width: '120px'
    }
  ]

  const generarReporte = () => {
    CatEmpleados.GetAllEmpleado()
      .then((response) => {
        setData(response.data)
        setFilteredData(response.data)
        setHeader('Informativo')
        setMsg('Se encontró la siguiente información...')
        setShowMsg(true)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg(`Ocurrió un error: ${e}`)
        setShowMsg(true)
      })
  }

  // const downloadExcel = () => {
  //   exportExcel(filteredData, 'ReporteEmpleados')
  // }

  // const exportExcel = (jsonData: any[], fileName: string) => {
  //   const Heading = [['idEmpNo', 'ac', 'name', 'NumEmpleado',  'HoraEntrada',  'HoraSalida', 'Activo', 'Payroll', 'Oficina',  'HoraSalidaComer' , 'HoraEntradaComer'  ]]
  //   const wb = XLSX.utils.book_new()
  //   const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
  //   XLSX.utils.sheet_add_aoa(ws, Heading)
  //   XLSX.utils.sheet_add_json(ws, jsonData, { origin: 'A2', skipHeader: true })
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
  //   XLSX.writeFile(wb, `${fileName}.xlsx`)
  // }



  function currentDate ():  string {
    var today = new Date()
    var dd = String(today.getDate()).padEnd(2,'0')
    var mm = String(today.getMonth() + 1  ).padStart(2, '0')
    var yyyy = today.getFullYear()
    return yyyy+'-' + mm + '-'  + dd
  }


  const downloadExcel = () => {
    // Filtrar las columnas relevantes para el reporte
    const filteredColumns = filteredData.map(({ numEmpleado,  name, departamento,  horaEntrada, horaSalida , oficina, fechaIngreso }) => ({
      
      
      numEmpleado,
      name,
      departamento, 
      horaEntrada,
      horaSalida,
      oficina, 
      fechaIngreso
    }));
  
    exportExcel(filteredColumns, 'ReporteEmpleados');
  };
  
  const exportExcel = (jsonData: any[], fileName: string) => {
    // Encabezados solo para las columnas seleccionadas
    const Heading = [[  'NumEmpleado',  'Nombre' , 'Departamento',   'HoraEntrada', 'HoraSalida' , 'Oficina']];
    
    const wb = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, Heading); // Agregar encabezados
    XLSX.utils.sheet_add_json(ws, jsonData, { origin: 'A2', skipHeader: true }); // Agregar datos
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${fileName}.xlsx`); // Descargar archivo
  };
  
  const filtraReporte = (e: any) => {
    setFiltro(e.target.value)
    const filtered = Data.filter(
      (item) =>
        String(item.numEmpleado).toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.name.toLowerCase().includes(e.target.value.toLowerCase())
    )
    setFilteredData(filtered)
  }

  const handleOpenModal = (empleado?: DTOEmpleado) => {
    if (empleado) {
      setEditando(true)
      setIdEmpNo(empleado.idEmpNo)
      setAc(empleado.ac)
      setNewName(empleado.name)
      setNumEpleado(empleado.numEmpleado)
      setNewHoraEntrada(empleado.horaEntrada)
      setNewHoraSalida(empleado.horaSalida)
      setActivo(empleado.activo)
      setPayroll(empleado.payroll) // Asigna el valor de Payroll
      setOficina(empleado.oficina) // Asigna el valor de Oficina
      setHoraEntradaComer(empleado.horaEntradaComer) // Asigna el valor de HoraEntradaComer
      setHoraSalidaComer(empleado.horaSalidaComer) // Asigna el valor de HoraSalidaComer
      setFechaIngreso(empleado.fechaIngreso)
    } else {
      setEditando(false)
      setIdEmpNo(0)
      setAc(0)
      setNewName('')
      setNumEpleado('')
      setNewHoraEntrada('')
      setNewHoraSalida('')
      setActivo(false)
      setPayroll('') // Inicializa en vacío
      setOficina('') // Inicializa en vacío
      setHoraEntradaComer('') // Inicializa en vacío
      setHoraSalidaComer('') // Inicializa en vacío
      setFechaIngreso('')
    }
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setIdEmpNo(0)
    setAc(0)
    setNewName('')
    setNewHoraEntrada('')
    setNewHoraSalida('')
    setActivo(false)
    setPayroll('')
    setOficina('')
    setHoraEntradaComer('')
    setHoraSalidaComer('')
    setFechaIngreso('')
    setShowModal(false)
  
  }

  const handleSave = () => {
    const empleadoData: DTOEmpleado = {
      idEmpNo: idEmpNo,
      ac: ac,
      name: newName,
      numEmpleado : newNumEmpleado, 
      departamento : newDepartamento, 
      horaEntrada: newHoraEntrada,
      horaSalida: newHoraSalida,
      activo: activo,
      payroll: payroll,
      oficina: oficina,
      horaEntradaComer: horaEntradaComer,
      horaSalidaComer: horaSalidaComer,
      fechaIngreso : fechaIngreso
    }

    const empleadoDataToSend = {
      ...empleadoData,
      activo: empleadoData.activo ? 1 : 0 // Convertimos boolean a 1/0 para el backend
    }

    if (editando) {
      CatEmpleados.PostInsertUpdate(empleadoDataToSend)
        .then(() => {
          setHeader('Actualizado')
          setMsg('Empleado actualizado exitosamente.')
          setShowMsg(true)
          generarReporte()
          handleCloseModal()
        })
        .catch((e: Error) => {
          setHeader('Error')
          setMsg(`Ocurrió un error al actualizar: ${e}`)
          setShowMsg(true)
        })
    } else {
      CatEmpleados.PostInsertUpdate(empleadoDataToSend)
        .then(() => {
          setHeader('Insertado')
          setMsg('Nuevo empleado insertado exitosamente.')
          setShowMsg(true)
          generarReporte()
          handleCloseModal()
        })
        .catch((e: Error) => {
          setHeader('Error')
          setMsg(`Ocurrió un error al insertar: ${e}`)
          setShowMsg(true)
        })
    }
  }

  const handleDelete = (NumEmpleado: string) => {
    CatEmpleados.DeleteEmpleado(NumEmpleado)
      .then((response) => {
        setHeader('Eliminado')
        setMsg('Se ha eliminado el empleado exitosamente.')
        setShowMsg(true)
        generarReporte()
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg(`Ocurrió un error: ${e}`)
        setShowMsg(true)
      })
  }

  const horas = Array.from({ length: 18 }, (_, i) => i + 6); // Horas de 6 AM a 11 PM
  const minutos = Array.from({ length: 60 }, (_, i) => i); // Minutos de 00 a 59

  const formatHora = (hora: number, minuto: number, segundo: number = 0) => {
    const suffix = hora < 12 ? 'AM' : 'PM'
    hora = hora % 12 || 12 // Convierte de 24 horas a 12 horas
    return `${hora}:${minuto.toString().padStart(2, '0')}:${segundo.toString().padStart(2, '0')} ${suffix}`
  }

  return (
    

    <div className="mt-2">
  <Card style={{ width: '100%', height: '100%' }}> {/* Card principal expandido a todo el ancho y alto */}
    <Card.Body style={{ padding: '20px' }}>
      <div className="container-fluid"> {/* Usamos container-fluid para ocupar todo el ancho */}
        {/* Contenedor de búsqueda y botones */}
        <div
          className="row mb-4"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Card
            style={{
              paddingTop: '10px',
              paddingBottom: '5px',
              width: '100%',
              height: 'auto',
            }}
          >
            <Row className="align-items-center" style={{ display: 'flex', justifyContent: 'space-between', padding: '0 20px' }}>
              {/* Cuadro de búsqueda */}
              <Col xs="auto">
                <Form.Control
                  type="text"
                  size="sm"
                  placeholder="Search..."
                  onChange={filtraReporte}
                  style={{
                    height: '35px',
                    width: '230px',
                    padding: '5px',
                  }}
                />
              </Col>

              {/* Botón de búsqueda */}
              <Col xs="auto">
                <Button
                  variant="primary"
                  size="sm"
                  onClick={generarReporte}
                  style={{
                    height: '35px',
                    width: '80px',
                    padding: '5px',
                    position: 'relative',
                    zIndex: 1000,
                  }}
                >
                  <BsSearch /> Buscar
                </Button>
              </Col>

              {/* Botón de agregar */}
              <Col xs="auto">
                <Button
                  size="sm"
                  variant="primary"
                  onClick={() => handleOpenModal()}
                  style={{
                    height: '35px',
                    width: '70px',
                    padding: '5px',
                    backgroundColor: 'orange',
                  }}
                >
                  <CgAdd />
                </Button>
              </Col>

              {/* Botón de exportar a Excel */}
              <Col xs="auto">
                <Button
                  size="sm"
                  variant="success"
                  onClick={downloadExcel}
                  style={{
                    height: '35px',
                    width: '100px',
                    padding: '5px',
                  }}
                >
                  <BsFileEarmarkExcel />
                  &nbsp;Excel
                </Button>
              </Col>
            </Row>
          </Card>
        </div>

        {/* Tabla de datos */}
        <Row>
          <Col xs={12}>
            <Card style={{ width: '100%', height: '600px' }}> {/* Card con altura incrementada */}
              <Card.Body style={{ overflow: 'auto', height: '100%' }}> {/* Permitir el desplazamiento si se requiere */}
                <DataTable
                  noHeader
                  defaultSortFieldId={'id'}
                  defaultSortAsc={true}
                  striped={true}
                  dense={true}
                  paginationPerPage={10}
                  pagination
                  highlightOnHover
                  columns={columnsRelacionBookPrecta}
                  data={filteredData}
                  pointerOnHover
                  responsive={true}
                  customStyles={{
                    table: {
                      style: {
                        width: '100%', // Ocupa todo el ancho del contenedor
                        height: '100%', // Ocupa todo el alto del contenedor
                      },
                    },
                    headCells: {
                      style: {
                        fontWeight: 'bold',
                        backgroundColor: '#f8f9fa',
                        padding: '10px',
                        fontSize: '16px',
                      },
                    },
                    rows: {
                      style: {
                        minHeight: '45px', // Aumenta la altura de cada fila para mayor visibilidad
                        fontSize: '15px', // Aumenta el tamaño de la fuente
                      },
                    },
                  }}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Card.Body>
  </Card>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{editando ? 'Editar Empleado' : 'Nuevo Empleado'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* <Form.Group>
              <Form.Label>ID Empleado</Form.Label>
              <Form.Control
                type="number"
                value={idEmpNo}
                onChange={(e) => setIdEmpNo(parseInt(e.target.value))}
              />
            </Form.Group> */}
            {/* <Form.Group>
              <Form.Label>AC</Form.Label>
              <Form.Control type="number" value={ac} onChange={(e) => setAc(parseInt(e.target.value))} />
            </Form.Group> */}
            <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control type="text" value={newName} onChange={(e) => setNewName(e.target.value)} />
            </Form.Group>
            <Form.Group> 
              <Form.Label>NumEmpleado</Form.Label>
              <Form.Control   type= "text" value={newNumEmpleado} onChange={(e) => setNumEpleado(e.target.value)  } />
            </Form.Group>
            <Form.Group>
              <Form.Label>Departamento</Form.Label>
              <Form.Control type="text" value={newDepartamento} onChange={(e) => setNewDepartamento(e.target.value)} />
            </Form.Group>

            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Hora Entrada</Form.Label>
                  <Form.Select value={newHoraEntrada} onChange={(e) => setNewHoraEntrada(e.target.value)}>
                    <option value="">Seleccione hora</option>
                    {horas.map((hora) =>
                      minutos.map((minuto) => (
                        <option key={`${hora}-${minuto}`} value={formatHora(hora, minuto)}>
                          {formatHora(hora, minuto)}
                        </option>
                      ))
                    )}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Hora Salida</Form.Label>
                  <Form.Select value={newHoraSalida} onChange={(e) => setNewHoraSalida(e.target.value)}>
                    <option value="">Seleccione hora</option>
                    {horas.map((hora) =>
                      minutos.map((minuto) => (
                        <option key={`${hora}-${minuto}`} value={formatHora(hora, minuto)}>
                          {formatHora(hora, minuto)}
                        </option>
                      ))
                    )}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            {/* <Form.Group>
              <Form.Label>Payroll</Form.Label>
              <Form.Control type="text" value={payroll} onChange={(e) => setPayroll(e.target.value)} />
            </Form.Group> */}

            {/* <Form.Group>
          <Form.Label>Payroll</Form.Label>
          <Form.Select value={payroll} onChange={(e) => setPayroll(e.target.value)}>
            <option value="">Seleccione una opción</option>
            <option value="STANDARD">STANDARD</option>
            <option value="SALARIO">SALARIO</option>
          </Form.Select>
        </Form.Group> */}

            <Form.Group>
              <Form.Label>Oficina</Form.Label>
              <Form.Control type="text" value={oficina} onChange={(e) => setOficina(e.target.value)} />
            </Form.Group>

            <Form.Group> 
            <Form.Label>FechaIngreso</Form.Label>
              <Form.Control
                defaultValue={fechaIngreso}
                type="date"
                name="Inicio"
                placeholder="Inicio"
                title="Inicio"
                alt="Inicio"
                data-date-format="YYYY-mm-dd"
                onChange={(e) => setFechaIngreso(e.target.value)}
                size="sm"
              />
            </Form.Group>

            <Row>
              
              {/* <Col md={6}>
                <Form.Group>
                  <Form.Label>Hora Salida Comer</Form.Label>
                  <Form.Select value={horaSalidaComer} onChange={(e) => setHoraSalidaComer(e.target.value)}>
                    <option value="">Seleccione hora</option>
                    {horas.map((hora) =>
                      minutos.map((minuto) => (
                        <option key={`${hora}-${minuto}`} value={formatHora(hora, minuto)}>
                          {formatHora(hora, minuto)}
                        </option>
                      ))
                    )}
                  </Form.Select>
                </Form.Group>
              </Col> */}
              {/* <Col md={6}>
                <Form.Group>
                  <Form.Label>Hora Entrada Comer</Form.Label>
                  <Form.Select value={horaEntradaComer} onChange={(e) => setHoraEntradaComer(e.target.value)}>
                    <option value="">Seleccione hora</option>
                    {horas.map((hora) =>
                      minutos.map((minuto) => (
                        <option key={`${hora}-${minuto}`} value={formatHora(hora, minuto)}>
                          {formatHora(hora, minuto)}
                        </option>
                      ))
                    )}
                  </Form.Select>
                </Form.Group>
              </Col> */}
            </Row>

            <Form.Group>
              <Form.Check
                type="checkbox"
                label="Activo"
                checked={activo}
                onChange={(e) => setActivo(e.target.checked)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSave}>
            {editando ? 'Actualizar' : 'Guardar'}
          </Button>
        </Modal.Footer>
      </Modal>

      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}
