import * as react from 'react'

import { useState } from 'react'
import DataTable from 'react-data-table-component'

import *  as XLSX from 'xlsx'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'
import { Button, Card, Form,  Col, Row, ToastHeader, CardBody,  } from 'react-bootstrap';
import { BsSearch, BsFileEarmarkExcel } from 'react-icons/bs'
import reportesServices from '../../Services/Reportes/reportes.services'
import DTOLogEdicion from '../../DTO/Reportes/DTOLogEdicion'
import { json } from 'stream/consumers'






export default  function RptLogEdicion  ()
{
    const [Data, setData]  = useState <Array<DTOLogEdicion>> ([])
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastMsg, setToastMsg] = useState<string>('');
    const [toastHeader, setToastHeader] = useState<string>('');
    const [toastColor, setToastColor] = useState<string>('');
    const [filteredData, setFilteredData] = useState<Array<DTOLogEdicion>> (
        []
    )

    const [filtro, setFiltro] = useState('')

    const generarReporte = ()  => {
        reportesServices.getRptLogEdicion()
        .then((response)   =>  {
            setData(response.data) 
            setFilteredData(response.data)
            setToastMsg('Se econtro la siguiente informacion');
            setToastHeader('Informativo');
            setToastColor('success');
            setShowToast(true);

        } )
        .catch((e: Error)   =>  {
            setToastMsg('No se ha introducido fechas validas')
      setToastHeader('Error')
      setToastColor('danger')
      setShowToast(true)
      return; 
        })



    }




    const columnsConcepts = [
        {
            name: 'id', 
            width:  '100px', 
            selector: (row : DTOLogEdicion) => row.id, 
            sortable: true, 
            filter: true
        }, 
        {
            name: 'EmpNo', 
            width :'100px', 
            selector : (row : DTOLogEdicion )  => row.empNo, 
            sortable: true, 
            filter: true, 

        }, 
        {
            name: 'Name', 
            width :'150px', 
            selector : (row : DTOLogEdicion )  => row.name, 
            sortable: true, 
            filter: true, 

        }, 
        {
            name: 'Fecha', 
            width :'150px', 
            selector : (row : DTOLogEdicion )  => row.fecha, 
            sortable: true, 
            filter: true, 

        }, 
        {
            name: 'UsuarioEdicion', 
            width :'150px', 
            selector : (row : DTOLogEdicion )  => row.usuarioEdicion, 
            sortable: true, 
            filter: true, 

        }, 
        {
            name: 'FechaHoraEdicion', 
            width :'150px', 
            selector : (row : DTOLogEdicion )  => row.fechaHoraEdicion, 
            sortable: true, 
            filter: true, 

        }, 
        {
            name: 'CamposActualizados', 
            width :'500px', 
            selector : (row : DTOLogEdicion )  => row.camposActualizadosUser, 
            sortable: true, 
            filter: true, 

        }, 
    ]


    const donwloadExel = ()  => {
        exportExcel(filteredData, 'ReporteEdicion'); 
    }; 

    const exportExcel = (jsonData : any[] , fileName : string  )  =>  {
        const Heading = [ 
            [
            'Id', 
            'EmpNo',
            'Name',
            'Fecha', 
            'UsuarioEdicion', 
            'FechaHoraEdicion', 
            'CamposActualizadosUser'
            ]
        ]

        
        const wb = XLSX.utils.book_new()
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
        XLSX.utils.sheet_add_aoa(ws, Heading)
        XLSX.utils.sheet_add_json(ws, jsonData, { origin: 'A2', skipHeader: true })
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
        XLSX.writeFile(wb, `${fileName}.xlsx`)
        const range = XLSX.utils.decode_range(ws['!ref']!)
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const address = XLSX.utils.encode_col(C) + '1'
          if (!ws[address]) continue
          ws[address].v = ws[address].v.toUpperCase()
        }

    }



    const filtraReporte = (e: any)  =>  {
        const searchText = e.target.value.toLowerCase(); 
        setFiltro(searchText)
  
        const filtered = Data.filter((iteam) =>  {
            return (
                (iteam.empNo && iteam.empNo.toString().toLocaleUpperCase().includes(searchText))  || 
                (iteam.name  && iteam.name.toString().toLocaleLowerCase().includes(searchText)   ) ||
                (iteam.usuarioEdicion && iteam.usuarioEdicion.toString().toLocaleLowerCase().includes(searchText))
  
            )
        } )
  
        setFilteredData(filtered)
       
    } 




    return(
        <div className='mt-2'> 
        
        <Card.Body>
        <Form>
          <Row className="align-items-center">
            

            <Col xs={2}>
              <Form.Control
                type="text"
                size="sm"
                placeholder="Search..."
                onChange={ (e) =>  {
                  filtraReporte(e)
                }  }
              //  onChange={filtros}
                style={{ height: '10px', padding: '5px' }}
              />
            </Col>

            <Col xs={2}>
              <Button
                // size="sm"
                variant="success"
                onClick={donwloadExel}
                style={{ width: '35%' }}
              >
                <BsFileEarmarkExcel />
                &nbsp;Excel
              </Button>
            </Col>

            <Col xs={2}>
              <Button
                variant="primary"
                onClick={generarReporte} 
                style={{ width: '40%' }}
              >
                <BsSearch />
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>
        </Card.Body>



        <MsgInformativo
      show={showToast}
      msg={toastMsg}
      header={toastHeader}
      msgColor={toastColor}
      closeToast={setShowToast}
    />
        
    
        <Card className='mt-3'> 
            <Card.Body> 
                <div className='ag-theme-alpine' style={{ height: 500, width: '100%'}}>

                    <DataTable 
                     noHeader 
                     defaultSortFieldId={''}
                     defaultSortAsc = {true}
                     striped= {true}
                     dense = {true}
                     paginationPerPage={10}
                     pagination 
                     highlightOnHover 
                     columns={columnsConcepts}
                     data={filteredData}
                     pointerOnHover
                    />
                </div>
            </Card.Body>
        </Card>
        
        </div>


    )




}