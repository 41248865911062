import React from 'react'
import ReactDOM from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './Store/store'
import Dashboard from './Componest/Dashboard/Dashboard'
import Login from './Componest/Loging/Login'
import Logout from './Componest/Logout/logout'
import ResetCredentials from './Componest/Loging/ResetCredentials'
import Register from './Componest/Register/register'
import UserControl from './Componest/UserControl/UserControl'
import ForgotPassword from './Componest/Loging/ForgotPassword'
import PIN from './Componest/Loging/PIN'
import CatNotificaciones from './Componest/Catalogos/CatNotificaciones'
import CatRelacionBookPrecta from './Componest/Catalogos/CatRelacionBookPrecta/CatRelacionBookPrecta'
import FileUploadExel from './Componest/Catalogos/FileUploadExel'
import FileUploadExelWorkingHours  from './Componest/Catalogos/CatWorkingHours/FileUploadExelWorkingHours'
import RptTotalHours from './Componest/Reportes/RptTotalHours/RptTotalHours'
import CatEmpleados from './Componest/Catalogos/CatEmpleados/CatEmpleados'
import RptLogEdicion from './Componest/Reportes/RptLogEdiciom'
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

function PageNotFound() {
  return (
    <div style={{ textAlign: 'center', paddingTop: '300px' }}>
      <h2>Sorry, no matching page</h2>
    </div>
  )
}

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <HashRouter>
        <Routes>
          <Route path='/' element={<App />}>
            <Route path='/' element={<Dashboard />} />
            <Route path='/login' element={<Login />} />
            <Route path='/forgotPassword' element={<ForgotPassword />} />
            <Route path='/PIN' element={<PIN />} />
            <Route path='/logout' element={<Logout />} />
            <Route path ='/Reset' element={<ResetCredentials />} />
            <Route path='/Register' element={<Register />} />
            <Route path='/CatNotificaciones' element={<CatNotificaciones/>} />
            <Route path='/RptTotalHours' element = {<RptTotalHours/>}  /> 
            <Route path='FileUploadExel' element= {<FileUploadExel/>} />
            <Route path='/UserControl' element={<UserControl />} />
            <Route path='/CatRelacionBookPrecta' element ={<CatRelacionBookPrecta />} />
            <Route path='*' element={<PageNotFound />} />
            <Route path='FileUploadExelWorkingHours' element = {<FileUploadExelWorkingHours/>}  />  
            <Route path='CatEmpleados' element ={<CatEmpleados/>} />
            <Route path='RptLogEdition' element= {<RptLogEdicion/>   }   />    
          </Route>
        </Routes>
      </HashRouter>
    </React.StrictMode>
  </Provider>
)
reportWebVitals()
